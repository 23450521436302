import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  ShowProps,
  Button,
  EditButton,
  ReferenceField,
  TopToolbar,
  RichTextField,
  FunctionField,
} from 'react-admin';
import { Divider } from '@mui/material';

export const EmployerShow = (props: ShowProps): JSX.Element => {
  return (
    <Show
      {...props}
      actions={
        <FunctionField
          render={(props: any) => <EmployerShowActions {...props} />}
        />
      }
    >
      <SimpleShowLayout>
        <h4>Required fields</h4>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="slug" />
        <TextField source="organisation_type" />
        <TextField source="billing_type" />
        <TextField source="category" />
        <TextField source="main_contact" />
        <Divider style={{ width: '100%' }} />
        <h4>Optional fields</h4>
        <BooleanField source="page_active" />
        <RichTextField source="description" />
        <NumberField source="employees" />
        <NumberField source="founded" />
        <TextField source="website" />
        <TextField source="linkedin" />
        <TextField source="facebook" />
        <TextField source="instagram" />
        <TextField source="testimonial" />
        <TextField source="testimonial_from" />
        <TextField source="logo" />
        <TextField source="cover_photo" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <Divider style={{ width: '100%' }} />
        <h4>Parent organisation field</h4>
        <ReferenceField source="parent_id" reference="employer">
          <TextField source="name" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};
const EmployerShowActions = (record: any): JSX.Element => {
  return (
    <TopToolbar>
      <EditButton record={record} />
      {/* Add your custom actions */}
      <Button
        color="primary"
        label="View employer page on site"
        onClick={() => {
          const baseUrl = process.env.REACT_APP_DEV_WEBSITE;

          window.open(`${baseUrl}/employers/${record?.slug}`, '_blank');
        }}
      />
      <Button
        color="secondary"
        label="Generate report"
        href={`/report/employer/${record.id}`}
      />
    </TopToolbar>
  );
};
